module.exports = function (boApp)
{
  boApp.config(['$routeProvider', 'originUrl', function($routeProvider, originUrl) {
    $routeProvider.when('/companies', {
      template: require('../partials/companies.html'),
      controller: 'CompanyCtrl'
    });
    $routeProvider.when('/', {
      template: require('../partials/ondemand.html'),
      controller: 'OnDemandCtrl'
    });
    $routeProvider.when('/warehouses', {
      template: require('../partials/warehouses.html'),
      controller: 'WarehouseCtrl'
    });
    $routeProvider.when('/warehouse/:warehouseId', {
      template: require('../partials/warehouse.html'),
      controller: 'WarehouseCtrl'
    });
    $routeProvider.when('/ondemand', {
      template: require('../partials/ondemand.html'),
      controller: 'OnDemandCtrl'
    });
    $routeProvider.otherwise({
      redirectTo: '/'
    });
  }]);
};
