const angular = require("angular");
require('angular-i18n/angular-locale_fr-fr.js');
require("../lib/angular-table.js");

// Application declaration
module.exports = angular.module('boApp', [
      require("angular-route"), require('angular-cookies'),
      require('angular-sanitize'), require('angular-ui-bootstrap'),
      'angular-table'])
  .run(['$templateCache', function ($templateCache) {
    $templateCache.put('navbar.html', require('../partials/navbar.html'));
    $templateCache.put('comments.html', require('../partials/comments.html'));
    $templateCache.put('sidebar.html', require('../partials/sidebar.html'));
    $templateCache.put('alert.html', require('../partials/misc/alert.html'));
  }]);
