require("../favicon.ico");

require("bootstrap/less/bootstrap.less");
require("font-awesome/less/font-awesome.less");
require("../css/app.css");

var boApp = require("./app.js");

require("./services.js")(boApp);
require("./components.js")(boApp);
require("./controllers.js")(boApp);
require("./filters.js")(boApp);
require("./routes.js")(boApp);
