module.exports = function (boApp)
{
  var $ = require("jquery");
  var Utils = require("./utils.js");

  // Domain whitelisting
  boApp.config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'https://*.'+window.location.host+'/**'
    ]);
  });

  // Constants
  boApp.constant('maxPages', 5);
  boApp.value('pageSize', 25);

  var env = require('./urls.js');

  var hostname = window.location.hostname;
  boApp.constant('apiUrl', env[hostname].apiUrl);
  boApp.constant('originUrl', env[hostname].originUrl);
  boApp.constant('environment', env[hostname].environment);
  boApp.constant('reactBo', env[hostname].reactBo);

  boApp.constant('loginPath', '/login');
  boApp.constant('authUrl', '/api/user/');

  boApp.constant('articleUrl', '/api/article/');
  boApp.constant('clientUrl', '/api/client/');
  boApp.constant('transactionUrl', '/api/transaction/');

  boApp.constant('commentUrl', '/api/comment/');
  boApp.constant('companyUrl', '/api/company/');
  boApp.constant('warehouseUrl', '/api/warehouse/');
  boApp.constant('lotUrl', '/api/lot/');
  boApp.constant('stockTemplateUrl', '/api/stock-template/');
  boApp.constant('stockUrl', '/api/stock/');
  boApp.constant('ondemandUrl', '/api/ondemand/');
  boApp.constant('transactionEntityUrl', '/api/transaction/');
  boApp.constant('entityUrl', '/api/entity/');
  boApp.constant('userUrl', '/api/users/');
  boApp.constant('depotUrl', '/api/depot/');

  boApp.constant('productUrl', '/api/product/');

  // Globals
  boApp.run(['$http', '$rootScope', 'apiUrl', 'originUrl', 'environment', 'reactBo',
  function ($httpProvider, $rootScope, apiUrl, originUrl, environment, reactBo) {
    $httpProvider.defaults.withCredentials = true;

    $rootScope.apiUrl = apiUrl;
    $rootScope.originUrl = originUrl;
    $rootScope.environment = environment;
    $rootScope.reactBo = reactBo;
  }]);

  function readCookie(name) {
    // Permet de récupérer les cookies dès l'initialisation de l'appli
    // sans avoir à injecter le service $cookies ou $cookieStore pas
    // encore instancié à ce stade
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  boApp.factory('XSRFInterceptor', [function() {
    var XSRFInterceptor = {
      "request": function(config) {
        if (!config.hasOwnProperty('disableXSRFInterceptor') || !config.disableXSRFInterceptor) {
          var token = readCookie('csrftoken');
          if (token)
            config.headers['X-CSRFToken'] = token;
        }
        return config;
      }
    };
    return XSRFInterceptor;
  }]);

  boApp.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('XSRFInterceptor');
  }]);

  // Alert messages service
  boApp.service('Alert', ['$route', function($route) {
    var alerts = [];

    this.getAlerts = function () {
      return alerts;
    };

    this.showMessage = function (type, msg, reload) {
      if(msg == null) {
        msg = "Erreur inconnue";
      }
      if (arguments.length < 3) {
        reload = false;
      }
      alerts.push({
        'type': type,
        'msg': msg,
        'reload': reload
      });
    };

    this.closeAlert = function (index) {
      reload = alerts[index].reload;
      alerts.splice(index, 1);
      if (reload) {
        $route.reload();
      }
    };
  }]);

  boApp.service('authService', function ($rootScope, $q, $http, $location, apiUrl, originUrl, authUrl, loginPath, Alert) {
    return $http.get(apiUrl+authUrl).then(function (response) {
      if (!response.data.id) {
        window.location.replace(apiUrl+loginPath);
      } else {
        $rootScope.user = response.data;
      }
    }, function (response) {
      Utils.showResponseErrors(response, Alert);
      return $q.reject("Auth error");
    });
  });

  var genericProvider = require("./genericProvider.js");

  boApp.service('articleProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'articleUrl', genericProvider]);
  boApp.service('clientProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'clientUrl', genericProvider]);
  boApp.service('transactionProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'transactionUrl', genericProvider]);

  boApp.service('commentProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'commentUrl', genericProvider]);
  boApp.service('companyProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'companyUrl', genericProvider]);
  boApp.service('warehouseProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'warehouseUrl', genericProvider]);
  boApp.service('orderProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'orderUrl', genericProvider]);
  boApp.service('lotProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'lotUrl', genericProvider]);
  boApp.service('stockTemplateProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'stockTemplateUrl', genericProvider]);
  boApp.service('stockProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'stockUrl', genericProvider]);
  boApp.service('depotProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'depotUrl', genericProvider]);
  boApp.service('productProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'productUrl', genericProvider]);
  boApp.service('ondemandProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'ondemandUrl', genericProvider]);
  boApp.service('transactionEntityProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'transactionEntityUrl', genericProvider]);
  boApp.service('entityProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'entityUrl', genericProvider]);
  boApp.service('userProvider', ['$http', '$rootScope', '$q', 'Alert', 'apiUrl', 'userUrl', genericProvider]);
};
