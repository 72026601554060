// Generic API provider which handles pagination
module.exports =
  function genericProvider ($http, $rootScope, $q, Alert, origin,
                            url) {
    this.get = function (id) {
      return $http.get(origin+url+id+'/')
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.post = function (obj) {
      return $http.post(origin+url, obj)
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.postAll = function (arr) {
      var promises = [];
      for (var i=0 ; i < arr.length ; i++) {
        promises.push(this.post(arr[i]));
      }
      return $q.all(promises);
    };

    this.put = function (id, obj) {
      return $http.put(origin+url+id+'/', obj)
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.putAll = function (arr) {
      var promises = [];
      for (var i=0 ; i < arr.length ; i++) {
        promises.push(this.put(arr[i].id, arr[i]));
      }
      return $q.all(promises);
    };

    this.patch = function (id, obj) {
      return $http.patch(origin+url+id+'/', obj)
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.patchAll = function (arr) {
      var promises = [];
      for (var i=0 ; i < arr.length ; i++) {
        promises.push(this.patch(arr[i].id, arr[i]));
      }
      return $q.all(promises);
    };

    this.del = function (id) {
      $http.delete(origin+url+id+'/')
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.delAll = function (arr) {
      var promises = [];
      for (var i=0 ; i < arr.length ; i++) {
        promises.push(this.del(arr[i].id));
      }
      return $q.all(promises);
    };

    this.filter = function (filters) {
      return $http.get(origin+url, { params: filters })
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.all = function () {
      return $http.get(origin+url)
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    this.page = function (pageNo) {
      return $http.get(origin+url, {'params': {'page': pageNo} })
        .then(function (response) {
          return response.data;
        }, showErrors);
    };

    function showErrors (reponse) {
      if ($rootScope){
        var Response = {'status':reponse.status, 'data':reponse.data[0]};
      } else {
        var Response = "Request error";
      }
      var Utils = require("./utils.js");
      Utils.showResponseErrors(reponse, Alert);
      return $q.reject(Response);
    };
  };
