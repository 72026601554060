module.exports = {
  'backoffice.foodles.co': {
    'apiUrl': 'https://api.foodles.co',
    'originUrl': window.location.origin+'/',
    'environment': 'production',
    'reactBo': 'https://bo.foodles.co',
  },
  'backoffice.dev.foodles.co': {
    'apiUrl': 'https://api.dev.foodles.co',
    'originUrl': window.location.origin+'/',
    'environment': 'staging',
    'reactBo': 'https://bo.dev.foodles.co',
  },
  '': {
    'apiUrl': 'http://localhost:8000',
    'originUrl': window.location.protocol+"//"
                +window.location.pathname.replace('/index.html', '/'),
    'environment': 'dev',
    'reactBo': 'http://localhost:3000',
  }
};

// compatibilite avec npm run serve
module.exports['localhost'] = module.exports[''];
