const Utils = require("./utils.js");

module.exports = function (boApp)
{
  boApp.component('photoComponent', {
    template: require('../partials/misc/photo-modal.html'),
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&'
    },
    controller: function () {
      var $ctrl = this;

      $ctrl.$onInit = function () {
        $ctrl.url = $ctrl.resolve.url;
      };
    }
  });
};
