module.exports =
{
  pageChanged: function ($scope, provider, filters, namespace)
  {
    if (filters) {
      filters.page = $scope.currentPage;
      provider.filter(filters).then(function (data) {
        createPagination(data);
      });
    } else {
      provider.page($scope.currentPage).then(function (data) {
        createPagination(data);
      });
    }

    function createPagination(data){
      if (namespace){
        $scope.pagination = {};
        $scope.pagination[namespace] =  {'results' : data.results, 'totalitems' : data.count, 'currentpagesize' : data.results.length};
      } else {
        $scope.results = data.results;
        $scope.totalItems = data.count;
        $scope.currentPageSize = data.results.length;
      }
    }
  },


  loadAllPaginatedResults: function ($q, provider, filters) {
    var deferred = $q.defer();
    var results = [];

    filters = filters || {};
    filters['page'] = 1;
    loadOnePage(filters);

    return deferred.promise;

    function loadOnePage (filters) {
      provider.filter(filters).then(function (data) {
        Array.prototype.push.apply(results, data.results);
        if (data.next) {
          filters['page']++;
          loadOnePage(filters);
        } else {
          deferred.resolve(results);
        }
      });
    }
  },

  showResponseErrors: function (response, Alert) {
    if (response.status == 404) {
      Alert.showMessage('danger', "L'élément recherché n'a pas été trouvé", false);
    } else if (response.data.hasOwnProperty('non_field_errors')) {
      response.data['non_field_errors'].forEach(function (item) {
        Alert.showMessage('danger', item, false);
      });
    } else if (response.data.hasOwnProperty('detail')) {
      Alert.showMessage('danger', response.data['detail'], false);
    } else if (response.data instanceof Array) {
      response.data.forEach(function (msg) {
        Alert.showMessage('danger', msg, false);
      });
    } else {
      Alert.showMessage('danger', "Erreur de connexion au serveur.", false);
      console.log("Showing errors from response ", response);
    }
  },

  openProductPhotoModal: function (product, uibModal) {
    var modalInstance = uibModal.open({
      animation: true,
      component: 'photoComponent',
      resolve: {
        url: function () {
          return product.image || product.image_url;
        }
      }
    });
    modalInstance.result.then(function (result) {}, function () {});
  },
};
